@import './settings.scss';
@import './typography.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  padding: 0;
  background: $color-background;
  font-family: $font-text;
  font-size: $base-size;
  color: $color-dark;
  line-height: 20px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  
  &:hover {
    color: $color-main;
    transition: $transition;
  }
}