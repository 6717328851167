@import '../../../styles/settings.scss';

.navBar {
    background: rgba(0, 0, 0, 0.29);
    border-radius: $radius;
    padding: 0 35px;
    height: 97px;
    display: flex;
    align-items: center;
    transition: max-height 3s ease-in-out;

    .logo {
        display: flex;
        justify-content: flex-start;
        flex: 20%;
    }

    .mainList {
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
        font-size: calc($base-size - 2px);
        font-weight: 500;
        line-height: 16px;
        flex: 54%;

        li a small {
            text-transform: uppercase;
        }

        li.dropdownParent {
            &:hover ul.dropdown {
                display: block;
                transition: $transition;
            }
        }

        .dropdown {
            position: absolute;
            left: -5%;
            display: none;
            z-index: 1;
            border-radius: $radius;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            background: rgba(0, 0, 0, 0.29);
            
            
            li {
                padding: 5px;
            }

            a {
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    border-radius: 4px;
                    background-color: $color-dark;
                    bottom: 0;
                    left: 0;
                    transform-origin: right;
                    transform: scaleX(0);
                    transition: transform 0.3s ease-in-out;
                }

                &:hover::before {
                    transform-origin: left;
                    transform: scaleX(1);
                }
            }
        }
    }

    .secondaryList {
        display: flex;
        flex: 24%;
        justify-content: space-evenly;
        align-items: center;
        font-weight: 600;
        font-size: $base-size;
        line-height: 19px;

        button {
            cursor: pointer;
            display: flex;
            border-style: none;
            background-color: transparent;
            color: $color-light;
            
            svg {
                font-size: 25px;
            }
            
            &.tooltip {
                position: relative;
                display: inline-block;
            }

            &.tooltip .tooltipText {
                visibility: hidden;
                width: 120px;
                background-color: #555;
                color: #fff;
                text-align: center;
                border-radius: 6px;
                padding: 5px 0;
                position: absolute;
                z-index: 1;
                bottom: 125%;
                left: 50%;
                margin-left: -60px;
                opacity: 0;
                transition: opacity 0.3s;
            }

            &.tooltip .tooltipText::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: #555 transparent transparent transparent;
            }

            &.tooltip:hover .tooltipText {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .hamburgerWrapper {
        position: relative;
        width: 26px;
        height: 18px;
        margin-left: 15%;
    }

    .hamburger {
        display: none;
        @include btn(0%, 51%);
      
        .iconLeft {
          @include icon(2px, 13px);
          left: 0px;
      
          &:before {
            @include icon-before(2px, 13px, -9px);
          }
      
          &:after {
            @include icon-after(2px, 13px, 9px);
          }
      
          &:hover {
            cursor: pointer;
          }
        }
      
        .iconRight {
          @include icon(2px, 13px);
          left: 13px;
      
          &:before {
            @include icon-before(2px, 13px, -9px);
          }
      
          &:after {
            @include icon-after(2px, 13px, 9px);
          }
        }
      
        &.open {
          .iconLeft {
            transition-duration: $duration;
            background: transparent;
      
            &:before {
              transform: rotateZ(45deg) scaleX(1.4) translate(2px, 2px);
            }
      
            &:after {
              transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -2px);
            }
          }
      
          .iconRight {
            transition-duration: $duration;
            background: transparent;
      
            &:before {
              transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 2px);
            }
      
            &:after {
              transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -2px);
            }
          }
        }
      
        &:hover {
          cursor: pointer;
        }
    }
    
    transition: height 1s;

    .massagers {
      flex: 20%;
      display: none;
      flex-direction: column;
      justify-content: space-between;
  
      &Icons {
        display: flex;
        margin-bottom: 25px;

        span {
          margin-left: 10px;
          display: flex;
          border-radius: 100%;
          position: relative;
          width: 23px;
          height: 22px;
  
          a {
            position: absolute;
            top: 4px;
            right: 3.5px;
          }
  
          &:hover {
            a {
              color: $color-light;
            }
          }
  
          &.telegram {
            background: linear-gradient(90deg, rgba(55,174,226,1) 0%, rgba(30,150,200,1) 100%);
          }
  
          &.viber {
            background-color: #7F4DA0;
          }
  
          &.whatsapp {
            background-color: #25D366;
          }
        }

        .Instagram {
          transition: $transition;
          background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
          svg {
            color: $color-light;
            transition: $transition;
          }
        }
  
      }
  
      &Email {
        display: flex;
        flex-direction: column;
  
        a {
          display: flex;
          align-items: center;
  
          svg {
            margin-right: 10px;
          }
        }
  
        p {
          color: #6D6D6D;
          font-size: calc($base-size - 4px);
        }
      }
    }

    .tabletsImageMenu {
      display: none;
    }

    &.open {
      background: rgba(0, 0, 0, 0.701);
      height: 89vh;
    }

}

@media (max-width: 776px) {
  
}

@media (max-width: 1025px) {
    .navBar {
      display: block;
      position: relative;

        .mainList {
            display: none;
            overflow: hidden;
            li a small {
              font-size: 16px;
            }
        }

        .secondaryList {
          position: relative;
          top: 39px;
          align-items: flex-end;
          flex-direction: column;

          .phoneNumber {
            display: none;
          }
        }

        .hamburger {
            display: block;
        }

        .logo {
            display: block;
            position: absolute;
            top: 12px;
        }

        .tabletsImageMenu {
          position: absolute;
          left: 34px;
          top: 104px;

          img {
            border-radius: 10px;
            width: 340px;
            height: 430px;
            object-fit: cover;
          }
        }

        &.open {
          transition: height 2s ease-in-out;

          .tabletsImageMenu {
            display: block;
            animation: dropAnimation 2s ease-in;
          }

          .mainList {
            position: absolute;
            display: flex;
            flex-direction: column;
            right: 33px;
            align-items: flex-end;
            top: 130px;

            li {
              animation: dropAnimation 2s ease-in;
              &:not(:last-child) {
                margin-bottom: 30px;
              }
            }
          }

          .massagers {
            animation: dropAnimation 2s ease-in;
            display: flex;
            position: absolute;
            right: 33px;
            top: 450px
          }
        }
    }
}