@import '../../../styles/settings.scss';

.contactFrom {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
  height: 429px;
  background-color: #F9F9F9;
  border-radius: $radius;
  padding: 52px 40px 61px 40px;
  box-shadow: 0px 0px 63px rgb(126 126 126 / 20%);
  
  .textBlock {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .title {
      color: $color-dark;
      font-size: calc($base-size + 26px);
      font-weight: 600;
      line-height: 0.93;
      margin-bottom: 14px;
    }

    .content {
      color: $color-dark;
      line-height: 1.25;
      max-width: 225px;
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 32px;

    input {
      width: 100%;
      border: 0.5px solid #BDBDBD;
      border-radius: $radius;
      color: #6d6d6d;
      font-size: calc($base-size - 2px);
      font-weight: 300;
      padding: 16px 28px;

      &:focus {
        outline: none;
        border: 1px solid $color-hover;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      &:last-child {
        margin-bottom: 15px;
      }
    }

    button {
      cursor: pointer;
      margin-bottom: 9px;
      padding: 13px 0;
      width: 100%;
      color: $color-light;
      border: none;
      font-family: $font-text;
      text-align: center;
      background-color: $color-main;
      border-radius: $radius;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;

      &:hover {
        background-color: $color-hover;
        transition: $transition;
      }
    }
  }

}

@media (max-width: 1025px) {
  .contactFrom {
    height: 270px;
    width: 100%;
    margin-top: 15px;
    padding: 15px;
    .textBlock {
      flex-direction: column;
      justify-content: center;
      .title {
        font-size: calc($base-size + 24px);
      }
      .content {
        max-width: 100%;
      }
    }
    form {
      margin-top: 15px;
      
      .inputsBlock {
        display: flex;
        flex-direction: row;
        margin-bottom: 12px;
        width: 100%;
        input {
          &:not(:last-child) {
            margin-bottom: 0px;
          }
    
          &:last-child {
            margin-bottom: 0px;
            margin-left: 5px;
          }
        }
      }
    }
  }
}