@import '../../../styles/settings.scss';

.scrollButton {
  opacity: 0;
  transition: opacity 1s ease-in-out;
  padding: 8px 8px 0px 8px;
  background: rgba(0, 0, 0, 0.51);
  color: $color-main;
  border: none;
  border-radius: 50px;
  position: fixed; 
  right: 2%;
  bottom: 2%;
  font-size: 2rem;
  z-index: 1;
  cursor: pointer;

  // svg {
  //   transform: translateY(0px);
  //   animation: popup 3s ease infinite;
  //   -webkit-animation: popup 3s ease infinite;
  // }

  &.visible {
    opacity: 1;
  }
}