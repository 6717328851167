@import '../../../styles/settings.scss';

.Blog {
  margin: 120px auto;
  position: relative;

  a {
    color: $color-dark;
    transition: $transition;
  }
  
  .titles {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: right;

    div {
      border-bottom: 1px solid #B3B3B3;
      padding-bottom: 40px;
      flex: 50%;

      &:nth-child(even) {
  
        a:hover {
          svg {
            color: $color-main;
          }
        }
        
        svg {
          font-size: 13px;
        }
      }
      

      &:nth-child(odd) {
        text-align: left;
        margin-right: 28px;
      }
    }
  }

  .BlogList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &Block {
      flex: 48%;
      display: flex;
      min-height: 300px;
      justify-content: space-between;
      margin-bottom: 38px;
      padding: 19px 0;
      border-bottom: 1px solid #B3B3B3;

      &:nth-child(odd) {
        margin-right: 28px;
      }
      
      &Image {
        flex: 42%;
        margin-right: 29px;
        img {
          border-radius: $radius;
          max-width: 290px;
          object-fit: fill;
        }
      }

      &Content {
        flex: 50%;

        p {
          font-weight: 500;

          small {
            color: #B3B3B3;
          }

          b {
            font-weight: 700;
          }
        }

        a small {
          border-bottom: 1px solid;
          font-weight: 500;
        }
      }
    }
  }

  .showMore {
    position: absolute;
    bottom: -65px;
    left: 45%;
    display: flex;
    flex-direction: column;

    &Btn{
      font-family: $font-text;
      font-size: 16px;
      padding: 0;
      margin: 5px 0 5px 0;
      border: none;
      background-color: transparent;
      cursor: pointer;
      transition: $transition;

      &:hover {
        color: $color-main;
        border-color: $color-main;
      }
    }
  }
}

@media (max-width: 1556px) {
  .Blog {
    .BlogList {
      &Block {
        flex: 100%;
      }
    }
  }
}

@media (max-width: 717px) {
  .Blog {
    .BlogList {
      &Block {
        flex-direction: column;

        &Image {
          text-align: center;
        }
      }
    }
  }
}