@import '../../../styles/settings.scss';

.ourServices {
  margin: 100px auto 45px auto;
  padding-top: 20px;
  position: relative;
  display: flex;
  justify-content: center;

  &Wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &Block {
      flex: 33.3%;
      position: relative;
      max-width: 380px;
      max-height: 300px;
      padding: 87px 40px 47px 40px;
      margin: 0 10px 75px 10px;
      border-radius: $radius;

      .iconBlock {
        font-size: 40px;
        position: absolute;
        top: -40px;
        left: 20px;
        padding: 24px;
        background-color: $color-background;
        border-radius: 50px;
        transition: $transition;
      }
      
      h3 {
        margin-bottom: 29px;
        position: relative;
        transition: $transition;
      }

      .arrowUpRight {
        margin-left: 2px;
        visibility: hidden;
        overflow: hidden;
        position: absolute;
        font-size: calc($base-size - 3px);
      }

      &:not(:first-child) {
        background-color: $color-light;
        cursor: pointer;
      }

      &:hover {
        h3, .iconBlock {
          color: $color-main;
          transition: $transition;
        }
        .arrowUpRight {
          visibility: visible;
          svg {
            animation: lineUpRight 1s ease 1;
            -webkit-animation: lineUpRight 1s ease 1;
          }
        }
      }
    }
  }

  .showMoreBtn {
    font-family: $font-text;
    font-size: 16px;
    padding: 0;
    bottom: 0px;
    border: none;
    background-color: transparent;
    position: absolute;
    cursor: pointer;
    transition: $transition;

    &:hover {
      color: $color-main;
      border-color: $color-main;
    }
  }
}

@media (max-width: 905px) {
  .ourServices {
    &Wrapper {
      flex-direction: column;
      :first-child {
        text-align: center;
      }
      &Block {
        flex: auto;
      }
    }
  }
}

@media (max-width: 1517px) {
  .ourServices {
    &Wrapper {
      justify-content: space-evenly;
    }
  }
}