@import '../../../styles/settings.scss';

.footer {
  background-color: $color-dark;
  padding: 70px 10% 38px 10%;
  color: $color-light;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: calc($base-size - 2px);

  svg {
    font-size: $base-size;
  }

  a {
    color: $color-light;

    &:hover {
      color: $color-main;
    }
  }

  .logo {
    flex: 8%;
  }

  .menu {
    display: flex;
    flex: 20%;
    flex-wrap: wrap;
    justify-content: space-between;

    ul li a small {
      font-weight: 500;
      line-height: 14px;
      text-transform: uppercase;
    }

    &FirstCol {
      margin-right: 35px;

      :not(:last-child) {
        margin-bottom: 23px;
      }
    }

    &SecondCol {
      :not(:last-child) {
        margin-bottom: 23px;
      }
    }
  }

  .phone {
    flex: 24%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;
    font-size: $base-size;
  }

  .massagers {
    flex: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &Icons {
      display: flex;

      span {
        margin-left: 10px;
        display: flex;
        border-radius: 100%;
        position: relative;
        width: 23px;
        height: 22px;

        a {
          position: absolute;
          top: 4px;
          right: 3.5px;
        }

        &:hover {
          a {
            color: $color-light;
          }
        }

        &.telegram {
          background: linear-gradient(90deg, rgba(55,174,226,1) 0%, rgba(30,150,200,1) 100%);
        }

        &.viber {
          background-color: #7F4DA0;
        }

        &.whatsapp {
          background-color: #25D366;
        }
      }

      .Instagram {
        transition: $transition;
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
        svg {
          color: $color-light;
          transition: $transition;
        }
      }

    }

    &Email {
      display: flex;
      flex-direction: column;

      a {
        display: flex;
        align-items: center;

        svg {
          margin-right: 10px;
        }
      }

      p {
        color: #6D6D6D;
        font-size: calc($base-size - 4px);
      }
    }
  }

  .socialMedia {
    flex: 14%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span :not(:first-child) {
      margin-left: 10px;
    }

    &Icons {
      display: flex;

      span {
        width: 22px;
        height: 22px;
        position: relative;
        background-color: $color-light;
        border-radius: 100%;
      }

      a {
        color: $color-dark;
        position: absolute;
        top: 3px;
        left: 3px;
      }
      
      .Instagram {
        
        &:hover {
          transition: $transition;
          background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
          svg {
            color: $color-light;
            transition: $transition;
          }
        }
      }

      .facebook {
        
        &:hover {
          transition: $transition;
          background: #4267B2;
          svg {
            color: $color-light;
            transition: $transition;
          }
        }
      }

      .vk {
        
        &:hover {
          transition: $transition;
          background: #4C75A3;
          svg {
            color: $color-light;
            transition: $transition;
          }
        }
      }
    }
  }

  .copyRight {
    margin-top: 59px;
    padding-top: 20px;
    flex: 100%;
    border-top: 1px solid #404040;
  }
}

@media (max-width: 1025px) {
  .footer {
    padding: 70px 5% 38px 5%;
  }
}