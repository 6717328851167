@import '../../../styles/settings.scss';

.aboutUs {
  padding: 80px 0;
  display: flex;
  flex-wrap: wrap;

  .aboutCompany {
    margin-bottom: 80px;
    max-width: 772px;
    flex: 60%;
    display: flex;
    flex-wrap: wrap;

    h2 {
      margin-bottom: 53px;
      flex: 100%;
    }

    .longText {
      font-weight: 700;
      position: relative;
      padding-left: 11px;

      &::before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        height: 90%;
        width: 2px;
        background-color: $color-main;
      }
    }

    .shortText {
      flex: 100%;
      margin-top: 20px;
    }
  }

  .imageBlock {
    flex: 40%;
    background: url('../../../../public/img/aboutUs-bgImage.jpg') 100%/120% no-repeat;
  }

  .advantages {
    display: flex;
    flex-direction: column;
    width: 100%;

    &Title {
      margin-bottom: 59px;
    }
    
    &BlockWrapper {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      &Col1, &Col2{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }

    &Block {
      width: 254px;
      margin-right: 20px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;

      &Number {
        font-size: 60px;
        font-weight: 700;
        line-height: 73px;
        padding: 9px 30px;
        border-radius: 50px;
        background-color: $color-background;
        color: $color-main;
        margin-right: 20px;
      }

      &Number1 {
        padding: 9px 34px;
      }
    }
  }
}

@media (max-width: 1370px) {
  .aboutUs {
    .advantages {
      &Block {
        flex: 45%;
      }
    }
  }
}