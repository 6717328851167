@import '../../../styles/settings.scss';

.ourReviews {
  padding-top: 114px;
  padding-bottom: 135px;

  h2 {
    margin-bottom: 44px;
  }

  .carousel {
    display: flex;
    flex-wrap: wrap;

    &Item {
      display: flex;
      flex-direction: column;
      flex: 20%;
      justify-content: flex-end;
      margin-bottom: 20px;
      
      &:not(:last-child) {
        margin-right: 30px;
      }
      
      &Content {
        background-color: #F3F3F3;
        padding: 30px;
        border-radius: 60px;
        border-bottom-left-radius: 0;
        margin: 0 10px;
      }

      &Profile {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 20px;

        .photo {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 61px;
          height: 61px;
          border-radius: 100%;
          overflow: hidden;
          margin-right: 12px;
          background-color: #F3F3F3;
          color: $color-main;
          opacity: 80%;
          font-size: 25px;
          font-weight: 600;
          letter-spacing: 1px;
        }

        // .contactInfo {
        //   p:last-child {
        //     color: #A7A7A7;
        //   }
        // }
      }
    }
  }
}