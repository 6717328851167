// main colors
$color-background: #F3F3F3;
$color-light: #FFFFFF;
$color-dark: #0A0A0A;
$color-main: #FF8A34;

// secondary colors
$color-hover: #ED7D2B;

// main font
$font-text: 'Gilroy', sans-serif;

// main variables
$radius: 10px;
$base-size: 16px;
$transition: all 0.3s ease-in-out;
$duration: 1s;


/* keyframes */
@keyframes popup {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes lineUpRight {
  0%   {
    transform: translate(-30px, 30px);
  }
  100%   {
    transform: translate(0px, 0px);
  }
}

@keyframes dropAnimation {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* mixins */

/* hamburger mixins */
@mixin btn($left, $bottom) {
  position: absolute;
  width: 26px;
  height: 18px;
  bottom: $bottom;
  left: $left;
  transition-duration: $duration;
}

@mixin icon($height, $width) {
  transition-duration: $duration;
  position: absolute;
  height: $height;
  width: $width;
  top: 18px;
  background-color: $color-light;
}

@mixin icon-before($height, $width, $top) {
  transition-duration: $duration;
  position: absolute;
  width: $width;
  height: $height;
  background-color: $color-light;
  content: "";
  top: $top;
}

@mixin icon-after($height, $width, $top) {
  transition-duration: $duration;
  position: absolute;
  width: $width;
  height: $height;
  background-color: $color-light;
  content: "";
  top: $top;
}