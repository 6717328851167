@import '../../../styles/settings.scss';

.container {
    max-width: 80%;
    margin: 0 auto;

    &Padding {
        padding: 0 10%;
    }

    &White {
        background-color: $color-light;
    }
}

@media (max-width: 1025px) {
    .container {
        &Padding {
            padding: 0 5%;
        }
    }
}