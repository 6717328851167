@import '../fonts/Gilroy/stylesheet.css';
@import './settings.scss';

// heading
h1, h2, h3 {
    margin: 0;
    text-transform: uppercase;
}

h1 {
    font-size: calc($base-size + 32px);
    line-height: 100px;
}

h2 {
    font-size: calc($base-size + 14px);
    line-height: 36px;
}

h3 {
    font-size: calc($base-size + 4px);
    line-height: 24px;
}

// text
p {
    margin: 0;
}

small {
    font-size: calc($base-size - 2px);
    line-height: 17px;
}

small.tiny {
    font-size: calc($base-size - 4px);
}
