@import '../../../styles/settings.scss';

.notFound {
  position: relative;
  height: 100vh;

  &Wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 767px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
    padding: 15px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    .notFound404 {
      position: relative;
      height: 220px;

      h1 {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-size: 132px;
        margin: 0;
        background: linear-gradient(130deg, $color-main, #ffcd6f);
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        text-transform: uppercase;
      }
    }

    h2 {
      font-size: 33px;
      font-weight: 200;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 25px;
      letter-spacing: 3px;
    }

    p {
      font-size: 16px;
      font-weight: 200;
      margin-top: 0;
      margin-bottom: 25px;
    }

    a {
      color: $color-main;
      font-weight: 200;
      text-decoration: none;
      border-bottom: 1px dashed $color-main;
      border-radius: 2px;

      &:hover {
        color: #fff;
        background-color: $color-main;
        border-bottom: 1px solid $color-main;
      }
    }

    .notFoundSocial {
      a {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        width: 40px;
        font-size: 14px;
        color: $color-main;
        border: 1px solid #e9e9e9;
        border-radius: 50%;
        margin: 3px;
        -webkit-transition: .2s all;
        transition: .2s all;

        &:hover {
          color: #fff;
          background-color: $color-main;
          border-color: $color-main;
        }
      }
    }

  }
}