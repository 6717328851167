@import '../../../styles/settings.scss';

.contactUs {
  color: $color-light;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),  url(../../../../public/img/contact-us-bg.jpg) no-repeat;
    -moz-background-size: 100% 100%; /* Firefox 3.6+ */
    -webkit-background-size: 100% 100%; /* Safari 3.1+ и Chrome 4.0+ */
    -o-background-size: 100% 100%; /* Opera 9.6+ */
    background-size: 100% 100%;
  padding: 120px 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .content {
    flex: 60%;

    a {
      color: $color-light;
      border-bottom: 1px solid;

      &:hover {
        color: $color-main;
      }
    }

    h1 {
      font-weight: 700;
      font-size: calc($base-size + 24px);
      line-height: 48px;

      span {
        color: $color-main;
      }
    }

    p {
      font-weight: 400;
    }

    div.ourContacts {
      margin-top: 39px;
      font-weight: 600;

      p {
        margin-bottom: 10px;
      }
    }
  }

  .contactUsContactForm {
    flex: 50%;
    display: flex;
    justify-content: flex-end;
  }

}

@media (max-width: 1025px) {
  .contactUs {
    padding: 120px 5%;
    .content {
      margin-right: 10px;
    }
  }
}