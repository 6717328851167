@import '../../../styles/settings.scss';

.header {
  color: $color-light;
  height: 100vh;
  padding: 37px 10% 0 10%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),  url(../../../../public/img/hero-bg.jpg) no-repeat;
    -moz-background-size: 100% 100%; /* Firefox 3.6+ */
    -webkit-background-size: 100% 100%; /* Safari 3.1+ и Chrome 4.0+ */
    -o-background-size: 100% 100%; /* Opera 9.6+ */
    background-size: 100% 100%;
  overflow: hidden;

  a {
    color: $color-light;
  }

  .hero {
    margin-top: 75px;
    display: flex;
    justify-content: space-between;
  }

  .heroContent {
    margin-top: 34px;
    flex: 60%;
    max-width: 650px;

    h1 {
      margin-bottom: 37px;
      text-transform: uppercase;
      font-size: calc($base-size + 79px);
      font-weight: 700;
      line-height: 100px;
      position: relative;
      span {
        color: $color-main
      }

      &::after {
        content: "";
        position: absolute;
        width: 12%;
        left: 3px;
        bottom: -14px;
        height: 1px;
        background-color: $color-light;
      }
    }

    .heroContentSmall {
      max-width: 337px;
      margin-bottom: 72px;
      h3 {
        font-weight: 300;
        line-height: 23px;
        text-transform: none;
      }
    }
  }

  
  .heroContactForm {
    flex: 40%;
    display: flex;
    justify-content: flex-end;
  }

  .heroBtnWrapper {
    width: 134px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    button {
      position: relative;
      cursor: pointer;
      padding: 18px 18px;
      background: rgba(0, 0, 0, 0.51);
      border-color: transparent;
      border-radius: 30px;
      margin-right: 13px;

      svg {
        position: absolute;
        top: 9px;
        right: 8px;
        transform: translateY(0px);
        animation: popup 3s ease infinite;
        -webkit-animation: popup 3s ease infinite;
        font-size: 21px;
        color: $color-main
      }
    }
  }
}



@media (max-width: 1353px) {
  .header {
    padding: 37px 5% 0 5%;
  }
}

@media (max-width: 1151px) {
  .header {
    .heroContent {
      h1 {
        font-size: 74px;
        font-weight: 700;
        line-height: 77px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .header {
    .hero {
      flex-direction: column;
    }

    .heroContactForm {
      justify-content: center;
    }
  }
}

@media (min-width: 1700px) {
  .header {
    .hero {
      margin-top: 130px;
    }
  }
}

@media (min-width: 1900px) {
  .header {
    .hero {
      margin-top: 150px;
    }
  }
}